<template>
  <div class="prti">
    PRTI

  </div>
</template>

<script>
//import Inbox from '@/views/Inbox.vue'
//import Chat from '@/views/Chat.vue'

export default {
  name: 'PrivateTypeIndex',
  components: {
  //  Inbox,
    //Chat

  }
}
</script>
